import React from "react";

function HostNames({ hostNames }) {
  if (!hostNames.left.on && !hostNames.right.on) {
    return null;
  }
  return (
    <>
      {/* <div className="hostnames-left-outer-container">
        <div className="hostnames-left-inner-container">
          {hostNames.left.on ? (
            <div className="hostnames-left">{hostNames.left.value}</div>
          ) : (
            <div />
          )}
        </div>
      </div>
      <div className="hostnames-right-outer-container">
        <div className="hostnames-right-inner-container">
          {hostNames.right.on ? (
            <div className="hostnames-right">{hostNames.right.value}</div>
          ) : (
            <div />
          )}
        </div>
      </div> */}
    </>
  );
}

export default HostNames;
