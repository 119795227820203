import "./Counters.css";
import "./Global.css";
import "./LiveDonations.css";
import "./TopDonors.css";
import "./sections/Books/Books.css";

import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import io from "socket.io-client";
import Board from "./Board";
import ViewDonations from "./ViewDonations";

const ws = io("https://molham-board-api.herokuapp.com/");
// const ws = io("http://localhost:5001/");

function App() {
  const [websocket] = useState(ws);
  useEffect(() => {
    ws.on("reload page", (msg) => {
      window.location.reload(false);
    });
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Board websocket={websocket} />}></Route>
      <Route path="/donations" element={<ViewDonations />}></Route>
    </Routes>
  );
}

export default App;
