import React from "react";
import { formatCurrency, HasArabicCharacters } from "./utils";

import FlipMove from "react-flip-move";
function LiveDonations({ donors }) {
  const liveDonations = [...donors];
  return (
    <FlipMove className="live-donors-container">
      {liveDonations.slice(0, 4).map((donor) => {
        return (
          <div
            className={
              "live-donor-item text-item" +
              (donor.donation_message === "" ? " centered-name" : "")
            }
            key={donor.id}
          >
            <div>
              <div className="live-donor-item-name-amount">
                <div className="live-donor-item-name">
                  {donor.donor_name === "" ? "فاعل خير" : donor.donor_name}:
                </div>
                <div className="live-donor-item-amount">
                  {`$${formatCurrency(donor.amount)}`}
                </div>
              </div>
              {donor.donation_message ? (
                <div
                  className="live-donor-item-message"
                  style={{
                    textAlign:
                      donor.donation_message &&
                      HasArabicCharacters(donor.donation_message.charAt(0))
                        ? "right"
                        : "left",
                  }}
                >
                  {donor.donation_message.substring(0, 50)}
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </FlipMove>
  );
}

export default LiveDonations;
