export function formatCurrency(amount) {
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
export function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}
export const fetcher = (...args) => fetch(...args).then((res) => res.json());
export function HasArabicCharacters(text) {
  var regex = new RegExp(
    "[\u0600-\u06ff]|[\u0750-\u077f]|[\ufb50-\ufc3f]|[\ufe70-\ufefc]"
  );

  return regex.test(text);
}
export const filterUrl = (message) => {
  const regex = new RegExp(
    /(http(s)?:(\/)?\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  if (
    message !== null &&
    message !== undefined &&
    typeof message === "string"
  ) {
    const temp = message.replace(regex, "");
    return temp;
  }
  return "";
};
