import React from "react";

import FlipMove from "react-flip-move";
import seedrandom from "seedrandom";
const books = [
  "./assets/books/book-1.svg",
  "./assets/books/book-2.svg",
  "./assets/books/book-3.svg",
  "./assets/books/book-4.svg",
  "./assets/books/book-5.svg",
  "./assets/books/book-6.svg",
];
function getRandomElement(arr, random) {
  // Check if the array is empty
  if (arr.length === 0) {
    return undefined;
  }

  // Generate a random index
  var randomIndex = Math.floor(random * arr.length);

  // Return the random element
  return arr[randomIndex];
}
const selectRandomBook = (number, rng) => {
  const s = getRandomElement(books, rng);
  return s;
};
function Books({ total }) {
  const booksTotal = total;

  const rng = seedrandom("molhamteam");
  return (
    <FlipMove className="books-container">
      {Array(booksTotal)
        .fill(0)
        .slice(0, 200)
        .map((_, index) => {
          const src = selectRandomBook(index, rng());
          const flipClass =
            src === "./assets/books/book-1.svg" ? "flip-book" : "";
          return (
            <div className="book-container">
              <img src={src} className={`book-image ${flipClass}`} />
              <div className="book-border" />
            </div>
          );
        })}
    </FlipMove>
  );
}

export default Books;
