import React from "react";

function Labels({ top, bottom }) {
  return (
    <>
      {/* <div className="top-label">{top}</div>
      <div className="bottom-label">{bottom}</div> */}
    </>
  );
}

export default Labels;
