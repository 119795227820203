import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { bounceInUp, merge, tada, zoomInUp } from "react-animations";

import { FloatingLettersTextBuilder } from "react-animated-text-builders";
import { formatCurrency } from "./utils";
import useSound from "use-sound";
import effectSound from "./sound.mp3";
import { flipInX } from "react-animations";
import { Fireworks } from "fireworks-js/dist/react";
import { rollIn } from "react-animations";
import { bounceInLeft } from "react-animations";
import { rotateInDownLeft } from "react-animations";
import { fadeInLeftBig, fadeIn } from "react-animations";
import { bounce } from "react-animations";
// import heart from "./heart.gif";
// import booksLottie from "./house.json";
import booksLottie from "./books.json";

import Lottie from "lottie-react-web";
import sprinkles from "./sprinkels.gif";
import Reward from "react-rewards";
import OlympicMedal, { olympicMedal } from "./OlympicMedal";
import { medals } from "./constants";
const optionsFireworks = {
  speed: 3,
  delay: { min: 5, max: 15 },
  particles: 100,
};
const Emoji = "👪";
const styleFireworks = {
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  position: "fixed",
  // background: "rgba(0,0,0,0.7)",
};
const olympicBackground = (frequency) => {
  if (frequency === medals.gold) {
    return "#FFB900";
  } else if (frequency > medals.gold && frequency <= medals.bronze) {
    return "#A45B31";
  } else if (frequency >= medals.silver) {
    return "#CCCCCC";
  }
};
const olympicPlace = (frequency) => {
  if (frequency === medals.gold) {
    return "ذهبي";
  } else if (frequency > medals.gold && frequency <= medals.bronze) {
    return "برونزي";
  } else if (frequency >= medals.silver) {
    return "فضي";
  }
};
const olympicMonth = (frequency) => {
  if (frequency === 0) {
    return "دائمة";
  } else if (frequency === 1) {
    return "شهر";
  } else if (frequency === 2) {
    return "شهرين";
  } else if (frequency >= 3) {
    return `${frequency} أشهر`;
  }
};
function DonationPopup(props) {
  const [play, { stop }] = useSound(effectSound, { volume: 0.5 });
  const [queue, setQueue] = useState(0);
  // console.log(props, counter.current);
  const reward = useRef(null);
  useEffect(() => {
    if (props.enabled) {
      play();
      if (reward.current) {
        reward.current.rewardMe();
      }
    }
    return stop;
  }, [play, props]);
  useEffect(() => {
    const s = setInterval(() => {
      setQueue((prev) => prev + 1);
    }, 400);
    return () => clearInterval(s);
  }, [props]);

  return (
    <>
      {props.enabled && props.type === "olympic-donor" ? (
        <div className="popup-outer-container">
          {/* <Reward
            ref={reward}
            type="emoji"
            config={{ elementCount: 5, emoji: [olympicMedal(props.frequency)] }}
          /> */}
          <TadaDiv className="popup-container">
            <div className="popup-header">
              <div className="popup-header-text">{props.donor_name}</div>
              <div className="popup-header-text">شكراً</div>
            </div>

            <div className="popup-body row">
              <OlympicMedal frequency={props.frequency} />
              <div
                style={{
                  backgroundColor: olympicBackground(props.frequency),
                  padding: "10px",
                  borderRadius: "20px",
                }}
              >{`على تبرعكم ال${olympicPlace(props.frequency)}`}</div>
              <OlympicMedal frequency={props.frequency} />
            </div>
            <div className="popup-message popup-body">
              {`تبرع ${olympicPlace(props.frequency)} لمدة ${olympicMonth(
                props.frequency
              )}`}
            </div>
            <div className="popup-amount">
              <FloatingLettersTextBuilder
                floatingSpeed={2000}
                lettersAppearanceDelay={200}
                animationMaxMargin={"100px"}
                animationMinMargin={"0px"}
              >
                {`$${formatCurrency(props.amount.toString())}`}
              </FloatingLettersTextBuilder>
            </div>
            <div className="popup-message popup-body">
              {props.donation_message}
            </div>
          </TadaDiv>
        </div>
      ) : null}
      {props.enabled && props.type === "custom-message" ? (
        <div className="popup-outer-container">
          {/* <Reward
            ref={reward}
            type="emoji"
            config={{ elementCount: 5, emoji: ["⭐", Emoji] }}
          /> */}
          <FadeInDiv className="popup-container">
            <>
              {/* <img
                  src={sprinkles}
                  alt="sprinkels"
                  className="popup-center gif-size"
                /> */}
              <Lottie
                options={{ animationData: booksLottie, loop: false }}
                speed={1.5}
                className="gif-size"
                style={{ width: 200 }}
              />
              <div className="popup-message popup-body row">
                {props.donation_message}
              </div>
            </>
          </FadeInDiv>
        </div>
      ) : null}
      {props.enabled && props.type === "donor" ? (
        <div className="popup-outer-container">
          {/* <Reward
            ref={reward}
            type="emoji"
            config={{ elementCount: 5, emoji: ["⭐"] }}
          /> */}
          <TadaDiv className="popup-container">
            <div className="popup-header">
              <div className="popup-header-text">{props.donor_name}</div>
              <div className="popup-header-text">شكراً</div>
            </div>
            <div className="popup-body">{`على تبرعكم`}</div>
            <div className="row">
              <div className="medal">⭐</div>
              <div className="popup-amount">
                <FloatingLettersTextBuilder
                  floatingSpeed={2000}
                  lettersAppearanceDelay={200}
                  animationMaxMargin={"100px"}
                  animationMinMargin={"0px"}
                >
                  {`$${formatCurrency(props.amount.toString())}`}
                </FloatingLettersTextBuilder>
              </div>
              <div className="medal">⭐</div>
            </div>
            <div className="popup-message popup-body">
              {props.donation_message}
            </div>
          </TadaDiv>
        </div>
      ) : null}
      {props.enabled && props.type === "family" ? (
        <div className="popup-outer-container">
          {/* <Reward
            ref={reward}
            type="emoji"
            config={{ elementCount: 5, emoji: [Emoji, "⭐"] }}
          /> */}
          <TadaDiv className="popup-container">
            <div
              className="popup-header"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Lottie
                options={{ animationData: booksLottie, loop: false }}
                speed={1.5}
                className="gif-size"
              />
              <div className="popup-header-text">عدد الكتب المؤمنة</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Bounce>{Emoji}</Bounce>
              <div style={{ fontSize: "60px" }} className="popup-amount">
                <FloatingLettersTextBuilder
                  floatingSpeed={2000}
                  lettersAppearanceDelay={200}
                  animationMaxMargin={"100px"}
                  animationMinMargin={"0px"}
                >
                  {`${formatCurrency(props.family.number.toString())}`}
                </FloatingLettersTextBuilder>
              </div>
              <Bounce>{Emoji}</Bounce>
            </div>
            <div className="popup-body">كتاب</div>
          </TadaDiv>
        </div>
      ) : null}
      {props.enabled && props.type === "top-donor" ? (
        <div className="popup-outer-container">
          {/* <Reward
            ref={reward}
            type="emoji"
            config={{ elementCount: 5, emoji: [Emoji] }}
          /> */}
          <TadaDiv className="popup-container">
            <div className="popup-header">
              <div className="popup-header-text">
                {props.donor_name} متبرع جديد في الصدارة
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Bounce>{Emoji}</Bounce>
              <div className="popup-amount">
                <FloatingLettersTextBuilder
                  floatingSpeed={2000}
                  lettersAppearanceDelay={200}
                  animationMaxMargin={"50px"}
                  animationMinMargin={"0px"}
                >
                  {`$${formatCurrency(props.total_donations.toString())}`}
                </FloatingLettersTextBuilder>
              </div>
              <Bounce>{Emoji}</Bounce>
            </div>
          </TadaDiv>
        </div>
      ) : null}
      {props.enabled && props.type === "goal" ? (
        <div className="popup-outer-container">
          {/* <Reward
            ref={reward}
            type="emoji"
            config={{ elementCount: 5, emoji: [Emoji] }}
          /> */}
          <TadaDiv className="popup-container">
            <div className="popup-header">
              <div className="popup-header-text">لقد وصلنا إلى</div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Bounce>{Emoji}</Bounce>
              <div className="popup-amount">
                <FloatingLettersTextBuilder
                  floatingSpeed={2000}
                  lettersAppearanceDelay={200}
                  animationMaxMargin={"100px"}
                  animationMinMargin={"0px"}
                >
                  {`$${formatCurrency(props.goal.toString())}`}
                </FloatingLettersTextBuilder>
              </div>
              <Bounce>{Emoji}</Bounce>
            </div>
          </TadaDiv>
        </div>
      ) : null}
      {props.enabled && props.type === "big-goal" ? (
        <>
          {/* <Fireworks options={optionsFireworks} style={styleFireworks} /> */}
          <div className="popup2-outer-container">
            <Reward
              ref={reward}
              type="emoji"
              config={{ elementCount: 5, emoji: [Emoji] }}
            />
            <div className="popup2-container">
              <div className="popup2-header">
                {queue >= 1 ? (
                  <>
                    <Bounce>{Emoji}</Bounce>
                    <BounceInUp className="popup2-header-text">
                      وصلنا
                    </BounceInUp>
                  </>
                ) : null}

                {queue >= 2 ? (
                  <RollIn className="popup2-header-text">ا</RollIn>
                ) : null}

                {queue >= 3 ? (
                  <RollIn className="popup2-header-text">ا</RollIn>
                ) : null}
                {queue >= 4 ? (
                  <RollIn className="popup2-header-text">ا</RollIn>
                ) : null}

                {queue >= 5 ? (
                  <RollIn className="popup2-header-text">ا</RollIn>
                ) : null}

                {queue >= 6 ? (
                  <RollIn className="popup2-header-text">ا</RollIn>
                ) : null}

                {queue >= 7 ? (
                  <RollIn
                    className="popup2-header-text"
                    style={{ marginLeft: "10px" }}
                  >
                    ا
                  </RollIn>
                ) : null}
                {queue >= 8 ? (
                  <>
                    <BounceInUp className="popup2-header-text">إلى</BounceInUp>
                    <Bounce>{Emoji}</Bounce>
                  </>
                ) : null}
              </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {queue >= 9 && (
                  <div style={{ fontSize: "60px" }} className="popup2-amount">
                    <div
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      {`$${formatCurrency(props.goal.toString())}`
                        .split("")
                        .reverse()
                        .map((char, index) => {
                          return queue >= index + 9 ? (
                            <BounceIn key={index}>{char}</BounceIn>
                          ) : (
                            <div key={index} />
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default React.memo(DonationPopup);
const fadeInAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 1s ${fadeInAnimation};
`;
const tadaAnimation = keyframes`${tada}`;

const TadaDiv = styled.div`
  animation: 2s ${tadaAnimation};
`;
const familyAnimation = merge(tada, flipInX);
const boundinDownAnimation = keyframes`${tada}`;

const BoundinDownDiv = styled.div`
  animation: 1s ${boundinDownAnimation} infinite;
`;

const bounceAnimation = keyframes`${bounceInLeft}`;

const BounceIn = styled.div`
  animation: 1s ${bounceAnimation};
`;

const bounceAnimation2 = keyframes`${bounceInUp}`;

const BounceInUp = styled.div`
  animation: 1s ${bounceAnimation2};
`;
const bounceAnimation3 = keyframes`${fadeInLeftBig}`;

const RollIn = styled.div`
  animation: 1s ${bounceAnimation3};
`;
const bounceAnimation4 = keyframes`${bounce}`;

const Bounce = styled.div`
  animation: 1s ${bounceAnimation4};
`;
