import React from "react";
import { medals } from "./constants";
export const olympicMedal = (frequency) => {
  if (frequency === medals.gold) {
    return "🥇";
  } else if (frequency > medals.gold && frequency <= medals.bronze) {
    return "🥉";
  } else if (frequency >= medals.silver) {
    return "🥈";
  }
};
function OlympicMedal({ frequency }) {
  const medal = olympicMedal(frequency);
  return <div className="medal">{medal}</div>;
}

export default OlympicMedal;
