import {
  motion,
  useAnimationFrame,
  useMotionTemplate,
  useMotionValue,
} from "framer-motion";
import React, { useEffect, useState } from "react";
import { useElementSize } from "usehooks-ts";
import "./NewsBar.css";

const barWidth = 1164;
function NewsBar({ message, messageEn, on }) {
  const [squareArRef, { width: widthAr }] = useElementSize();
  const [squareEnRef, { width: widthEn }] = useElementSize();
  const [direction, setDirection] = useState(message ? "rtl" : "ltr");
  // const minDistance = (dir) => (dir === "rtl" ? -barWidth : barWidth);
  // const maxDistance = (dir) => (dir === "rtl" ? width : -width);
  const minDistanceAr = -barWidth;
  const maxDistanceAr = widthAr;
  const minDistanceEn = widthEn;
  const maxDistanceEn = -barWidth;
  const xAr = useMotionValue(minDistanceAr);
  const xEn = useMotionValue(minDistanceEn);
  const transformAr = useMotionTemplate`translateX(${xAr}px)`;
  const transformEn = useMotionTemplate`translateX(${xEn}px)`;

  useEffect(() => {
    xAr.set(minDistanceAr);
  }, [message, xAr, minDistanceAr]);

  useEffect(() => {
    xEn.set(minDistanceEn);
  }, [messageEn, xEn, minDistanceEn]);

  useAnimationFrame((time, delta) => {
    if (on) {
      if (direction === "rtl") {
        xAr.set(xAr.get() + 0.13 * delta);
      } else {
        xEn.set(xEn.get() - 0.13 * delta);
      }
      if (direction === "rtl" && xAr.get() >= maxDistanceAr && message) {
        xAr.set(minDistanceAr);
        if (messageEn) {
          setDirection("ltr");
        }
      }
      if (direction === "ltr" && xEn.get() <= maxDistanceEn && messageEn) {
        xEn.set(minDistanceEn);
        if (message) {
          setDirection("rtl");
        }
      }
    }

    // // x.set(x.get() + 0.13 * delta);
    // // if (x.get() >= maxDistance) {
    // //   x.set(minDistance);
    // // }
    // if (on) {
    //   if (direction === "rtl") {
    //     x.set(x.get() + 0.13 * 4 * delta);
    //   } else {
    //     x.set(x.get() - 0.13 * 4 * delta);
    //   }
    //   if (
    //     (direction === "rtl" && x.get() >= maxDistance("rtl")) ||
    //     (direction === "ltr" && x.get() <= maxDistance("ltr"))
    //   ) {
    //     messageEn
    //       ? setDirection(direction === "rtl" ? "ltr" : "rtl")
    //       : x.set(minDistance(direction === "rtl" ? "ltr" : "rtl"));
    //   }
    // }
  });
  return (
    <>
      {messageEn || message ? (
        <div className="news-bar-container">
          {message && (
            <motion.div
              className="news-bar-item"
              ref={squareArRef}
              style={{
                transform: transformAr,
                position: "absolute",
                right: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              dir={"rtl"}
              // style={{ transform: `translateX(1px)`, position: "absolute", right: 0 }}
            >
              <img
                src="/logo1.svg"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              />
              {message?.replace(/\\n/g, " ")}
              <img
                src="/logo1.svg"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              />
            </motion.div>
          )}
          {messageEn && (
            <motion.div
              className="news-bar-item"
              ref={squareEnRef}
              style={{
                transform: transformEn,
                position: "absolute",
                right: 0,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
              dir={"ltr"}
              // style={{ transform: `translateX(1px)`, position: "absolute", right: 0 }}
            >
              <img
                src="/logo1.svg"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              />

              {messageEn?.replace(/\\n/g, " ")}
              <img
                src="/logo1.svg"
                style={{
                  width: 40,
                  height: 40,
                  marginRight: 10,
                  marginLeft: 10,
                }}
              />
            </motion.div>
          )}
        </div>
      ) : null}
    </>
  );
}

export default NewsBar;
