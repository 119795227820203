import ProgressBar from "@ramonak/react-progress-bar";
import React, { useEffect } from "react";
import { useCountUp } from "react-countup";
const commonCounterOptions = {
  duration: 5,
  separator: ",",
  start: 0,
  delay: 0,
};

function Counters({ specialDonation, donators, families, total, units_left }) {
  const donatorsCountUpRef = React.useRef(null);
  const { update: donatorsUpdate } = useCountUp({
    ref: donatorsCountUpRef,
    end: donators,
    ...commonCounterOptions,
  });
  useEffect(() => {
    donatorsUpdate(donators);
  }, [donators, donatorsUpdate]);

  const familiesCountUpRef = React.useRef(null);
  const { update: familiesUpdate } = useCountUp({
    ref: familiesCountUpRef,
    end: families,
    ...commonCounterOptions,
  });
  useEffect(() => {
    familiesUpdate(families);
  }, [families, familiesUpdate]);

  const unitsLeftCountUpRef = React.useRef(null);
  const { update: unitsLeftUpdate } = useCountUp({
    ref: unitsLeftCountUpRef,
    end: units_left,
    ...commonCounterOptions,
  });
  useEffect(() => {
    unitsLeftUpdate(units_left);
  }, [units_left, unitsLeftUpdate]);

  const totalCountUpRef = React.useRef(null);
  const { update: totalUpdate } = useCountUp({
    ref: totalCountUpRef,
    end: total,
    prefix: "$",
    ...commonCounterOptions,
  });
  useEffect(() => {
    totalUpdate(total);
  }, [total, totalUpdate]);
  return (
    <>
      {/* {specialDonation ? (
        <div className="special-donation-container counters-item">
          <div className="special-donation-name">
            <div className="">{specialDonation.name}</div>
          </div>
          <div className="special-donation-amount">
            <div>{`$${formatCurrency(specialDonation.amount)}`}</div>
          </div>
        </div>
      ) : null} */}
      <div className="progress-bar">
        <ProgressBar
          completed={1000}
          maxCompleted={10000}
          bgColor={"#c94cbe"}
          baseBgColor="#dedcdf"
          dir="rtl"
          // maxCompleted={10000}
          // className="progress-bar_wrapper"
          // barContainerClassName="progress-bar_container"
          // completedClassName="progress-bar_barCompleted"
          // labelClassName="progress-bar_label"
          customLabel=" "
        />
      </div>
      <div className="donators-count">
        <div className="counters-item counter-font donators-count--counter-font">
          <div ref={donatorsCountUpRef} />
        </div>
      </div>
      <div className="families-count">
        <div className="counters-item counter-font">
          <div ref={familiesCountUpRef} />
        </div>
      </div>
      <div className="total-donations">
        <div
          className="counters-item counter-font "
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div ref={totalCountUpRef} />
          <div>مدفوع</div>
        </div>
      </div>
      <div className="required-donations">
        <div
          className="counters-item counter-font "
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
          }}
        >
          <div>
            $
            {Intl.NumberFormat("en", {
              currency: "usd",
            }).format(10000)}
          </div>
          <div>مطلوب</div>
        </div>
      </div>
      <div className="units-left-count">
        <div className="counters-item counter-font">
          <div ref={unitsLeftCountUpRef} />
        </div>
      </div>
    </>
  );
}

export default Counters;
