import React from "react";
import useSWR from "swr";
import { fetcher, formatCurrency, HasArabicCharacters } from "./utils";

import FlipMove from "react-flip-move";
import styled from "styled-components";
function ViewDonations() {
  const { data: allDonors } = useSWR(
    "https://api2.molhamteam.com/campaign_439_summary",
    fetcher,
    { refreshInterval: 10000 }
  );

  if (!allDonors) {
    return null;
  }
  const topDonors = [...allDonors.top_donors]
    .reverse()
    .sort((a, b) => parseInt(b.total_donations) - parseInt(a.total_donations));

  return (
    <Container>
      <LiveDonationsContainer>
        <Header>:التبرعات الجديدة</Header>
        <FlipMove className="live-donation-view-item-container">
          {allDonors.latest_donations.map((donor) => {
            return (
              <LiveDonationItem className="donation-view-item" key={donor.id}>
                <div>
                  <div className="live-donor-item-name-amount">
                    <Text className="live-donor-item-name">
                      {!donor.donor_name ? "فاعل خير" : donor.donor_name}:
                    </Text>
                    <Text className="live-donor-item-amount">
                      {`$${formatCurrency(donor.amount)}`}
                    </Text>
                  </div>
                  {donor.donation_message ? (
                    <Message
                      style={{
                        textAlign:
                          donor.donation_message &&
                          HasArabicCharacters(donor.donation_message.charAt(0))
                            ? "right"
                            : "left",
                      }}
                    >
                      {donor.donation_message}
                    </Message>
                  ) : null}
                </div>
              </LiveDonationItem>
            );
          })}
        </FlipMove>
      </LiveDonationsContainer>
      <TopDonorsContainer>
        <div
          className="donation-view-item"
          style={{ marginBottom: 20, textAlign: "right" }}
        >
          <Text>:اجمالي التبرعات</Text>
          <Text>{`$${formatCurrency(allDonors.total_donations)}`}</Text>
        </div>
        <div
          className="donation-view-item"
          style={{ marginBottom: 20, textAlign: "right" }}
        >
          <Text>:عدد المتبرعين</Text>
          <Text>{allDonors.total_donors}</Text>
        </div>
        <div
          className="donation-view-item"
          style={{ marginBottom: 20, textAlign: "right" }}
        >
          <Text>:عدد البيوت المؤمنة</Text>
          <Text>{allDonors.units_funded}</Text>
        </div>
        <Header>:اعلى المتبرعين</Header>
        <FlipMove>
          {topDonors.map((donor) => {
            return (
              <div className="top-donor-item donation-view-item" key={donor.id}>
                <Text className="top-donor-item-name">{donor.donor_name}:</Text>
                <Text className="top-donor-item-amount">
                  {`$${formatCurrency(donor.total_donations)}`}
                </Text>
              </div>
            );
          })}
        </FlipMove>
      </TopDonorsContainer>
    </Container>
  );
}

export default ViewDonations;
const Container = styled.div`
  padding: 1vh 7vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Header = styled.h2`
  text-align: right;
  font-size: 2rem;
`;
const TopDonorsContainer = styled.div`
  flex: 1;
`;
const LiveDonationsContainer = styled.div`
  margin-right: 2vw;
  flex: 2;
  border-right: 1px solid #000;
  padding-right: 2vw;
`;
const LiveDonationItem = styled.div`
  margin-bottom: 1.4vh;
  flex: 0 0 47%;
`;
const Text = styled.div`
  font-size: 20px;
`;
const Message = styled.p`
  font-size: 20px;
`;
