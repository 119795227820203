import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useSWR from "swr";
import Counters from "./Counters";
import DonationPopup from "./DonationPopup";
import HostNames from "./HostNames";
import Labels from "./Labels";
import LiveDonations from "./LiveDonations";
import NewsBar from "./NewsBar";
import Books from "./sections/Books/Books";
import { fetcher, filterUrl } from "./utils";
let newsTimeout;
const MODAL_DURATION = 8000;
const GOAL_MARGIN = 1000;
const UNITS_GOAL_MARGIN = 5;
function Board({ websocket }) {
  const [searchParams] = useSearchParams();
  const { data: allDonors } = useSWR(
    "https://api2.molhamteam.com/campaign_439_summary",
    fetcher,
    { refreshInterval: 5000 }
  );

  const [mainstate, setMainstate] = useState({
    celebrationArray: [],
    fireworksState: {
      enabled: false,
      donor_name: "",
      amount: "",
      donation_message: "",
      type: "",
      family: { number: 0, celebrated: false },
    },
    specialDonation: undefined,
    topDonors: [],
    olympicDonors: [],
    donationGoal: { goal: GOAL_MARGIN, celebrated: true },
    unitsGoal: { goal: UNITS_GOAL_MARGIN, celebrated: true },
    firstTime: true,
    customMessage: { message: "", celebrated: true, time: 0 },
    hostNames: {
      left: { value: "", on: false },
      right: { value: "", on: false },
    },
    lables: { top: "", bottom: "" },
    newsBar: {
      on: false,
      message: "",
      messageEn: "",
    },
  });
  //socket events useEffect
  useEffect(() => {
    websocket.on("message", (msg) => {
      const message = JSON.parse(msg);
      setMainstate((prev) => {
        return {
          ...prev,

          customMessage: {
            message: message.msg,
            time: message.time,
            celebrated: false,
          },
        };
      });
    });
    websocket.on("news", (news) => {
      const newsParsed = JSON.parse(news);
      console.log(newsParsed);
      if (newsParsed.on) {
        setMainstate((prev) => {
          return {
            ...prev,

            newsBar: {
              message: newsParsed.msg,
              messageEn: newsParsed.msgEn,
              on: true,
            },
          };
        });
        if (newsTimeout) clearTimeout(newsTimeout);
        newsTimeout = setTimeout(() => {
          setMainstate((prev) => {
            return {
              ...prev,
              newsBar: {
                on: false,
                message: "",
                messageEn: "",
              },
            };
          });
        }, parseInt(newsParsed.time));
      } else {
        setMainstate((prev) => {
          return {
            ...prev,
            newsBar: {
              on: false,
              message: "",
              messageEn: "",
            },
          };
        });
        if (newsTimeout) clearTimeout(newsTimeout);
      }
    });
    websocket.on("setLeft", (msg) => {
      console.log(msg);
      const message = JSON.parse(msg);
      setMainstate((prev) => {
        return {
          ...prev,
          hostNames: { ...prev.hostNames, left: message },
        };
      });
    });
    websocket.on("setRight", (msg) => {
      console.log(msg);
      const message = JSON.parse(msg);
      setMainstate((prev) => {
        return {
          ...prev,
          hostNames: { ...prev.hostNames, right: message },
        };
      });
    });
  }, []);
  //Feed donors to the celebration array
  useEffect(() => {
    if (allDonors && !mainstate.firstTime) {
      setMainstate((prev) => {
        return {
          ...prev,
          celebrationArray: [
            ...allDonors.latest_donations
              .filter(
                (donor) =>
                  !prev.celebrationArray.find((celeb) => {
                    return celeb.id === donor.id;
                  })
              )
              .map((elem) => ({
                ...elem,
                celebrated: false,
                donor_name:
                  filterUrl(elem.donor_name) === ""
                    ? "فاعل خير"
                    : filterUrl(elem.donor_name),
                donation_message: filterUrl(elem.donation_message),
              })),
            ...prev.celebrationArray,
          ],
          olympicDonors: [
            ...allDonors.latest_donations
              .filter(
                (donor) =>
                  donor.frequency &&
                  !prev.olympicDonors.find((celeb) => {
                    return celeb.id === donor.id;
                  })
              )
              .map((elem, index) => {
                return {
                  ...elem,
                  celebrated: false,
                  donor_name:
                    filterUrl(elem.donor_name) === ""
                      ? "فاعل خير"
                      : filterUrl(elem.donor_name),
                  donation_message: filterUrl(elem.donation_message),
                  // frequency: getRandomInt(0, 5),
                };
              }),
            ...prev.olympicDonors,
          ],
          topDonors: [
            ...allDonors.top_donors
              .filter(
                (donor) =>
                  !prev.topDonors.find((celeb) => {
                    return celeb.id === donor.id;
                  })
              )
              .map((elem) => ({
                ...elem,
                celebrated: false,

                donor_name:
                  filterUrl(elem.donor_name) === ""
                    ? "فاعل خير"
                    : filterUrl(elem.donor_name),
              })),
            ...prev.topDonors,
          ],
          fireworksState: {
            ...prev.fireworksState,

            family:
              allDonors.units_funded > prev.fireworksState.family.number
                ? { number: allDonors.units_funded, celebrated: false }
                : prev.fireworksState.family,
          },
          unitsGoal:
            prev.unitsGoal.goal <
            parseInt(allDonors.units_funded / UNITS_GOAL_MARGIN) *
              UNITS_GOAL_MARGIN
              ? {
                  goal:
                    (parseInt(allDonors.units_funded / UNITS_GOAL_MARGIN) + 1) *
                    UNITS_GOAL_MARGIN,
                  celebrated: false,
                }
              : { ...prev.unitsGoal },
          donationGoal:
            prev.donationGoal.goal <
            parseInt(allDonors.total_donations / GOAL_MARGIN) * GOAL_MARGIN
              ? {
                  goal:
                    (parseInt(allDonors.total_donations / GOAL_MARGIN) + 1) *
                    GOAL_MARGIN,
                  celebrated: false,
                }
              : { ...prev.donationGoal },
        };
      });
    } else if (allDonors && mainstate.firstTime) {
      setMainstate((prev) => {
        return {
          ...prev,
          celebrationArray: [
            ...allDonors.latest_donations
              .filter(
                (donor) =>
                  !prev.celebrationArray.find((celeb) => {
                    return celeb.id === donor.id;
                  })
              )
              .map((elem, index) => ({
                ...elem,
                celebrated: true,

                donor_name:
                  filterUrl(elem.donor_name) === ""
                    ? "فاعل خير"
                    : filterUrl(elem.donor_name),
                donation_message: filterUrl(elem.donation_message),
              })),
            ...prev.celebrationArray,
          ],
          topDonors: [
            ...allDonors.top_donors
              .filter(
                (donor) =>
                  !prev.topDonors.find((celeb) => {
                    return celeb.id === donor.id;
                  })
              )
              .map((elem, index) => {
                return {
                  ...elem,
                  celebrated: true,
                  donor_name:
                    filterUrl(elem.donor_name) === ""
                      ? "فاعل خير"
                      : filterUrl(elem.donor_name),
                };
              }),
            ...prev.topDonors,
          ],
          olympicDonors: [
            ...allDonors.latest_donations
              .filter(
                (donor) =>
                  donor.frequency &&
                  !prev.olympicDonors.find((celeb) => {
                    return celeb.id === donor.id;
                  })
              )
              .map((elem, index) => {
                return {
                  ...elem,

                  donor_name:
                    filterUrl(elem.donor_name) === ""
                      ? "فاعل خير"
                      : filterUrl(elem.donor_name),

                  donation_message: filterUrl(elem.donation_message),
                  celebrated: true,
                };
              }),
            ...prev.olympicDonors,
          ],
          firstTime: false,
          unitsGoal: {
            goal:
              parseInt(allDonors.units_funded / UNITS_GOAL_MARGIN) *
              UNITS_GOAL_MARGIN,
            celebrated: true,
          },
          donationGoal: {
            goal:
              parseInt(allDonors.total_donations / GOAL_MARGIN) * GOAL_MARGIN,
            celebrated: true,
          },
          specialDonation:
            allDonors.top_donors.length > 0
              ? {
                  name: allDonors.top_donors[0].donor_name,
                  amount: allDonors.top_donors[0].total_donations,
                }
              : undefined,
          fireworksState: {
            ...prev.fireworksState,
            // family: { number: allDonors.units_funded - 1, celebrated: true },
            family: { number: allDonors.units_funded, celebrated: true },
          },
        };
      });
    }
  }, [allDonors, mainstate.firstTime]);
  //check if the fireworks are running if they aren't find the first not celebrated donation
  //if found celebrate and change it's state
  useEffect(() => {
    let timer;

    if (!mainstate.fireworksState.enabled && !mainstate.firstTime) {
      let uncelebratedLiveDonorIndex = -1;
      mainstate.celebrationArray.forEach((element, index) => {
        if (element.celebrated === false) {
          uncelebratedLiveDonorIndex = index;
        }
      });
      let uncelebratedTopDonorIndex = -1;
      mainstate.topDonors.forEach((element, index) => {
        if (element.celebrated === false) {
          uncelebratedTopDonorIndex = index;
        }
      });
      let uncelebratedOlympicDonorIndex = -1;
      mainstate.olympicDonors.forEach((element, index) => {
        if (element.celebrated === false) {
          uncelebratedOlympicDonorIndex = index;
        }
      });
      if (uncelebratedLiveDonorIndex !== -1) {
        if (
          mainstate.celebrationArray[uncelebratedLiveDonorIndex].amount >= 0
        ) {
          setMainstate((prev) => {
            return {
              ...prev,
              fireworksState: {
                ...prev.fireworksState,
                enabled: true,
                donor_name:
                  prev.celebrationArray[uncelebratedLiveDonorIndex].donor_name,
                amount:
                  prev.celebrationArray[uncelebratedLiveDonorIndex].amount,
                donation_message:
                  prev.celebrationArray[uncelebratedLiveDonorIndex]
                    .donation_message,
                type: "donor",
              },
            };
          });
          timer = setTimeout(() => {
            setMainstate((prev) => {
              const newCeleb = [...prev.celebrationArray];
              newCeleb[uncelebratedLiveDonorIndex].celebrated = true;
              const specialDonation = {
                name: newCeleb[uncelebratedLiveDonorIndex].donor_name,
                amount: newCeleb[uncelebratedLiveDonorIndex].amount,
              };
              clearTimeout(timer);
              return {
                ...prev,
                fireworksState: {
                  ...prev.fireworksState,
                  enabled: false,
                  donor_name: "",
                  amount: "",
                  donation_message: "",
                  type: "",
                },
                celebrationArray: newCeleb,
                specialDonation,
              };
            });
          }, MODAL_DURATION);
        } else {
          setMainstate((prev) => {
            const newCeleb = [...prev.celebrationArray];
            newCeleb[uncelebratedLiveDonorIndex].celebrated = true;
            return {
              ...prev,
              celebrationArray: newCeleb,
            };
          });
        }
      } else if (!mainstate.unitsGoal.celebrated) {
        setMainstate((prev) => {
          return {
            ...prev,
            fireworksState: {
              ...prev.fireworksState,
              enabled: true,
              goal: prev.unitsGoal.goal - UNITS_GOAL_MARGIN,
              type: "family",
            },
          };
        });
        timer = setTimeout(() => {
          setMainstate((prev) => {
            clearTimeout(timer);
            return {
              ...prev,
              fireworksState: {
                ...prev.fireworksState,
                enabled: false,
                goal: "",
                type: "",
              },
              unitsGoal: {
                ...prev.unitsGoal,
                // goal: prev.unitsGoal.goal + UNITS_GOAL_MARGIN,
                celebrated: true,
              },
            };
          });
        }, MODAL_DURATION);
      } else if (uncelebratedOlympicDonorIndex !== -1) {
        setMainstate((prev) => {
          return {
            ...prev,
            fireworksState: {
              ...prev.fireworksState,
              enabled: true,
              type: "olympic-donor",
              donor_name:
                prev.olympicDonors[uncelebratedOlympicDonorIndex].donor_name,
              amount: prev.olympicDonors[uncelebratedOlympicDonorIndex].amount,
              frequency:
                prev.olympicDonors[uncelebratedOlympicDonorIndex].frequency,
              donation_message:
                prev.olympicDonors[uncelebratedOlympicDonorIndex]
                  .donation_message,
            },
          };
        });
        timer = setTimeout(() => {
          setMainstate((prev) => {
            clearTimeout(timer);
            const newCeleb = [...prev.olympicDonors];
            newCeleb[uncelebratedOlympicDonorIndex].celebrated = true;
            return {
              ...prev,
              olympicDonors: newCeleb,
              fireworksState: {
                ...prev.fireworksState,
                enabled: false,
                type: "",
                donor_name: "",
                amount: "",
                frequency: "",
                donation_message: "",
              },
            };
          });
        }, MODAL_DURATION);
      } else if (uncelebratedTopDonorIndex !== -1) {
        setMainstate((prev) => {
          return {
            ...prev,
            fireworksState: {
              ...prev.fireworksState,
              enabled: true,
              type: "top-donor",
              donor_name: prev.topDonors[uncelebratedTopDonorIndex].donor_name,
              total_donations:
                prev.topDonors[uncelebratedTopDonorIndex].total_donations,
            },
          };
        });
        timer = setTimeout(() => {
          setMainstate((prev) => {
            clearTimeout(timer);
            const newCeleb = [...prev.topDonors];
            newCeleb[uncelebratedTopDonorIndex].celebrated = true;
            return {
              ...prev,
              topDonors: newCeleb,
              fireworksState: {
                ...prev.fireworksState,
                enabled: false,
                type: "",
                donor_name: "",
                total_donations: "",
              },
            };
          });
        }, MODAL_DURATION);
      } else if (!mainstate.donationGoal.celebrated) {
        setMainstate((prev) => {
          return {
            ...prev,
            fireworksState: {
              ...prev.fireworksState,
              enabled: true,
              goal: prev.donationGoal.goal - GOAL_MARGIN,
              type: "big-goal",
            },
          };
        });
        timer = setTimeout(() => {
          setMainstate((prev) => {
            clearTimeout(timer);
            return {
              ...prev,
              fireworksState: {
                ...prev.fireworksState,
                enabled: false,
                goal: "",
                type: "",
              },
              donationGoal: { ...prev.donationGoal, celebrated: true },
            };
          });
        }, MODAL_DURATION);
      } else if (!mainstate.customMessage.celebrated) {
        setMainstate((prev) => {
          return {
            ...prev,
            fireworksState: {
              ...prev.fireworksState,
              enabled: true,
              donation_message: prev.customMessage.message,
              type: "custom-message",
            },
          };
        });
        timer = setTimeout(() => {
          setMainstate((prev) => {
            clearTimeout(timer);
            return {
              ...prev,
              fireworksState: {
                ...prev.fireworksState,
                enabled: false,
                donation_message: "",
                type: "",
              },
              customMessage: { celebrated: true, message: "" },
            };
          });
        }, mainstate.customMessage.time);
      }
    }
  }, [mainstate]);
  const celebratedOnly = mainstate.celebrationArray.filter(
    (elem) => elem.celebrated === true
  );
  return (
    <div
      style={{
        backgroundImage:
          searchParams.get("trans") === "0"
            ? "url(./assets/books-background.png)"
            : undefined,
      }}
      className="main"
    >
      {/* <NewYearCountDown /> */}
      {/* <Confetti
        width={1920}
        height={1080}
        recycle={true}
        tweenDuration={MODAL_DURATION}
        gravity={0.3}
        numberOfPieces={mainstate.fireworksState.enabled ? 50 : 0}
      /> */}
      {mainstate.fireworksState.enabled && (
        <DonationPopup
          {...mainstate.fireworksState}
          donationGoal={mainstate.donationGoal}
        />
      )}
      <Labels
        top={mainstate.lables.top}
        // top={"molham team"}
        bottom={mainstate.lables.bottom}
        // bottom={"molham team"}
      />
      <HostNames
        hostNames={mainstate.hostNames}
        // hostNames={{
        //   left: {
        //     value: "molham team",
        //     on: true,
        //   },
        //   right: {
        //     value: "molham team",
        //     on: true,
        //   },
        // }}
      />
      {mainstate.newsBar.on ? <NewsBar {...mainstate.newsBar} /> : null}
      {/* {allDonors ? <TopDonors donors={allDonors.top_donors} /> : null} */}
      <LiveDonations donors={celebratedOnly} />
      {allDonors ? <Books total={allDonors.units_left} /> : null}
      {allDonors ? (
        <Counters
          donators={allDonors.total_donors}
          families={allDonors.units_funded}
          units_left={allDonors.units_left}
          total={allDonors.total_donations}
          specialDonation={mainstate.specialDonation}
        />
      ) : null}
    </div>
  );
}

export default Board;
